import React from "react";
import { Helmet } from "react-helmet";
// eslint-disable-next-line
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

export default function blogs() {
  return (
    <>
      <Helmet>
        <title>Neel Gupta | Blogs</title>
      </Helmet>
      Blogs
    </>
  );
}
