import React, { useState } from "react";
import "./header.css";
import logo from "./logo192.png";
// eslint-disable-next-line
import { Link } from "react-router-dom";

export default function Header() {
  let [isMobile, setisMobile] = useState(false);

  return (
    <>
      <header className="header">
        <nav className={isMobile ? "nav nav-resp" : "nav"}>
          <Link to="/" className={isMobile ? "nav-head-resp" : "nav-head"}>
            <img src={logo} alt="neel" className="nav-img" />
            <p class="site-name">Neel Gupta</p>
          </Link>

          <ul className={isMobile ? "navbar resp" : "navbar"}>
            <Link to="/" className="links">
              <li> Home </li>
            </Link>
            <hr className="nav-hr" />
            <Link to="/blogs" className="links">
              <li> Blogs </li>
            </Link>
            <hr className="nav-hr" />
            <Link to="/services" className="links">
              <li> Services </li>
            </Link>
            <hr className="nav-hr" />
            <Link to="/about" className="links">
              <li> About </li>
            </Link>
          </ul>

          <div
            className={isMobile ? "burger burger-active" : "burger"}
            onClick={() => setisMobile(!isMobile)}
          >
            {isMobile ? (
              <i className="fas fa-times"></i>
            ) : (
              <i className="fas fa-bars bars"></i>
            )}
          </div>
        </nav>
      </header>
    </>
  );
}
