import "./App.css";
import Blogs from "./app/blogs/blogs.jsx";
import Header from "./app/header/header.jsx";
import Services from "./app/services/services.jsx";
import { Helmet } from "react-helmet";
// eslint-disable-next-line
import Hero from "./app/hero/hero.jsx";
// eslint-disable-next-line
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

function App() {
  return (
    <>
      <Helmet>
        <title>Neel Gupta | Home</title>
      </Helmet>

      <Router>
        <Header />
        <Routes>
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/services" element={<Services />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
